import React from 'react';
import AskBackBtn from "./AskBackBtn";
import classnames from "classnames";
import axios from 'axios';

class ChatInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typing: false,
            wrapperHeight: 0,
            matches: [],
            typingTimeout: 0,
            inputVal: ''
        }
    }

    handleChange = (e) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            inputVal: e.target.value,
            typing: false,
            typingTimeout: setTimeout(() => {
                axios.post(`${window.location.origin}/api/tester`, {
                    text: this.state.inputVal
                }).then(res => {
                    this.setState({ matches: res.data });
                });
            }, 15)
        });
    };

    renderAskBackBtn = () => {
        if(this.state.matches.length === 0) {
            return;
        }

        return this.state.matches.filter(m => m.expMatchPercent === 100).map((item, i) => (
            <AskBackBtn key={i} item={item} />
        ));
    };

    render() {
        return (
            <div className="publisher bt-1 border-light">
                <div className="askback">
                    <div className={classnames("askback__wrapper", { 'show': this.state.matches.length > 0 })}>
                        {this.renderAskBackBtn()}
                    </div>
                    <input
                        type="text"
                        className="publisher__input"
                        placeholder={this.props.placeholder}
                        name="msg"
                        onKeyPress={this.props.onKeyPress}
                        onChange={this.handleChange}
                        autoComplete="off"
                        autoFocus={true}
                    />
                </div>
            </div>
        );
    }
};

export default ChatInput;
