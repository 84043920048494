import React from 'react';

const AskBackBtn = (props) => {
    return (
        <div key={"ask_btn_a_tag_" + props.index}
            className="askback__btn"
        >
            {props.item.rule.qrtext}
        </div>
    )
};

export default AskBackBtn;
