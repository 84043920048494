const TIME_SEP_LIMIT = 3600;

const createNewGroupFromMsg = (message) => {
    return {
        timestamp: message.timestamp,
        groups: [createNewSpeaksGroupFromMsg(message)]
    };
};

const createNewSpeaksGroupFromMsg = (message) => {
    return {
        speaks: message.speaks,
        messages: [message.msg]
    };
};

const addNewMessageToList = (message, messages) => {
    if (messages.length === 0) {
        messages.push(createNewGroupFromMsg(message));
        return messages;
    }

    let lastGroupIdx = messages.length - 1;
    let lastGroup = messages[lastGroupIdx];
    let lastGroupTimestamp = lastGroup.timestamp;
    if (message.timestamp - lastGroupTimestamp >= TIME_SEP_LIMIT) {
        messages.push(createNewGroupFromMsg(message));

        return messages;
    }

    let lastGroupMessageIdx = lastGroup.groups.length - 1;
    let groupSpeaks = lastGroup.groups[lastGroupMessageIdx].speaks;
    if (message.speaks === groupSpeaks) {
        lastGroup.groups[lastGroupMessageIdx].messages.push(message.msg);

        return messages;
    }

    lastGroup.groups.push(createNewSpeaksGroupFromMsg(message));

    return messages;
};


export default {
    createNewGroupFromMsg,
    createNewSpeaksGroupFromMsg,
    addNewMessageToList,
};
