import React from 'react';
import logo from '../../images/logo_orange.png'

const ChatInfo = () => (
    <div className="info">
        <img src={logo} alt="OBOR Bank" />
        <div className="info__title">
            <b>Szia Péter,</b><br/>
            kérdezz tőlem bátran<br/> az OBOR Bankos<br/> pénzügyeiddel kapcsolatban!
        </div>
        <div className="info__desc">
            A bot intelligencia tájékoztatást képes adni a költései szokásaidról vagy akár segíthet abban, hogy elérd a megtakarítási céljaidat.
        </div>
        <hr/>
    </div>
);

export default ChatInfo;
