import React from 'react';

const Button = (props) => {
    if (props.item.type === "web_url") {
        return (
            <a href={props.item.url} target="_blank" rel="noopener noreferrer" className="media-button">
                {props.item.title}
            </a>
        )
    }

    return (
        <button className="media-button" onClick={e => props.onClick(e, props.item.payload, props.item.title)}>
            {props.item.title}
        </button>
    )
};

export default Button;
