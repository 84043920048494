import React from 'react';
import classnames from 'classnames';
import Typing from './Typing';

const Message = (props) => (
    <div className={classnames('media', {
        'media--user': props.speaks === 'me',
        'media--bot': props.speaks === 'bot',
    })}>
        <div className="media__body">
            <div className="media__bubble">
                <Typing animate={props.speaks === 'bot'}>
                    <span className="media__text">{props.text}</span>
                </Typing>
            </div>
        </div>
    </div>
);

export default Message;
