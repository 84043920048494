import React from 'react';
import classnames from 'classnames';

const QuickReply = (props) => {
    return (
        <a
            href="/"
            key={"qr_a_tag_" + props.index}
            className={classnames('media__option', {
                "selected": props.index === props.selected,
                'inactive': props.selected !== null
            })}
            onClick={e => {
                e.preventDefault();

                if (props.selected === null) {
                    let payload = props.reply.label;
                    if (
                        props.reply.value &&
                        props.reply.value.input &&
                        props.reply.value.input.text
                    ) {
                        payload = props.reply.value.input.text;
                    }

                    props.onClick(
                        e,
                        payload,
                        props.reply.label,
                        props.index
                    );
                }
            }}
        >
            {props.reply.label}
        </a>
    )
};

export default QuickReply;
