import React from 'react';
import Button from "./Button";

class Buttons extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(e, payload, text) {
        this.props.buttonClick(e, payload, text);
    }

    renderButton(button, i) {
        return <Button key={i} index={i} onClick={this.onClick} item={button} />;
    }

    renderButtons(buttons) {
        if (buttons) {
            return (
                <div className="media-buttons">
                    {buttons.map((reply, i) => this.renderButton(reply, i))}
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div className="media media--bot">
                <div className="media__body">
                    {this.props.text && <div className="media__bubble">{this.props.text}</div>}
                    {this.renderButtons(this.props.payload)}
                </div>
            </div>
        );
    }
}

export default Buttons;
