import React, {Fragment} from 'react';
import axios from 'axios/index';
import classnames from 'classnames';
import moment from 'moment';

import ChatHeader from './ChatHeader';
import ChatInfo from './ChatInfo';
import ChatTimeSep from './ChatTimeSep';
import ChatInput from './ChatInput';
import Message from './Message';
import Cards from './Cards';
import Buttons from './Buttons';
import QuickReplies from './QuickReplies';

import handleMsgGroups from '../helpers/handleMsgGroups';

class Chatbot extends React.Component {
    messagesEnd;

    constructor(props) {
        super(props);

        this.handleTextInputKeyPress = this.handleTextInputKeyPress.bind(this);
        this.handleQuickReplyPayload = this.handleQuickReplyPayload.bind(this);
        this.handleButtonPayload     = this.handleButtonPayload.bind(this);
        this.toggleVisibility        = this.toggleVisibility.bind(this);

        this.state = {
            messages: [],
            showBot: true
        };

        this.userId = null;
    }

    componentDidMount() {
        let urlParams = new URLSearchParams(window.location.search);
        this.userId = urlParams.get('userId') || 1;
        //this.dfEventQuery('conversation_start');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        }
    }

    updateState(messages) {
        return new Promise((resolve) => {
            this.setState({ messages }, resolve);
        });
    }

    async dfTextQuery(queryText, payload) {
        try {
            await this.updateState(handleMsgGroups.addNewMessageToList({
                speaks: 'me',
                timestamp: moment.utc().format('X'),
                msg: {
                    response_type: 'text',
                    text: queryText
                }
            }, this.state.messages));

            const res = await axios.post('/api/df_text_query', {
                text: payload ? payload : queryText,
                userID: this.userId
            });

            for (let msg of res.data.generic) {
                await this.updateState(handleMsgGroups.addNewMessageToList({
                    speaks: 'bot',
                    timestamp: moment.utc().format('X'),
                    msg: msg,
                }, this.state.messages));
            }
        } catch (e) {
            console.log(e);
        }
    }

    async dfEventQuery(eventName) {
        const res = await axios.post('/api/df_event_query', { event: eventName, userID: this.userId });
        console.log(res);

       for (let msg of res.data.generic) {
            await this.updateState(handleMsgGroups.addNewMessageToList({
                speaks: 'bot',
                timestamp: moment.utc().format('X'),
                msg: msg,
            }, this.state.messages));
        }
    }

    handleTextInputKeyPress (e) {
        if (e.key === 'Enter') {
            this.dfTextQuery(e.target.value);
            e.target.value = '';
        }
    }

    handleQuickReplyPayload(e, payload, text) {
        this.dfTextQuery(text, payload);
    }

    handleButtonPayload(e, payload, title) {
        e.preventDefault();
        let says = {
            speaks: 'me',
            msg: {
                text: {
                    text: title,
                }
            }
        };

        return this.setState({ messages: [...this.state.messages, says] }, () => {
            this.hideAllQuickReplyAfterOtherEvent(() => {
                this.dfEventQuery(payload);
            });
        });
    }

    toggleVisibility(e) {
        e.preventDefault();
        this.setState({ showBot: !this.state.showBot });
    }

    renderOneMessage(message, i) {
        if (
            message.msg &&
            message.msg.response_type === 'text'
        ) {
            return <Message key={i} speaks={message.speaks} text={message.msg.text} />
        } else if (
            message.msg &&
            message.msg.payload &&
            message.msg.payload.cards
        ) {
            return <Cards key={i} speaks={message.speaks} cards={message.msg.payload.cards} />;
        } else if (
            message.msg &&
            message.msg.payload &&
            message.msg.payload.buttons
        ) {
            return <Buttons
                key={i}
                speaks={message.speaks}
                text={message.msg.payload.text ? message.msg.payload.text : null }
                payload={message.msg.payload.buttons}
                buttonClick={this.handleButtonPayload}
            />;
        } else if (
            message.msg &&
            message.msg.response_type === 'option'
        ) {
            return <QuickReplies
                text={message.msg.title ? message.msg.title : null }
                key={i}
                replyClick={this.handleQuickReplyPayload}
                speaks={message.speaks}
                payload={message.msg.options}
            />
        }
    }

    renderGroups({ timestamp, groups }, i) {
        return [
            <ChatTimeSep key={i} timestamp={timestamp} />,
            groups.map((msgGroup, i) => {
                return (
                    <div key={i} className="media-group">
                        {
                            msgGroup.messages.map((message, i) => {
                                return this.renderOneMessage({
                                    speaks: msgGroup.speaks,
                                    msg: message
                                }, i);
                            })
                        }
                    </div>
                );
            })
        ];
    }

    renderMessages(stateMessages) {
        if (stateMessages) {
            return (
                <Fragment>
                    <ChatInfo />
                    {
                        stateMessages.map((group, i) => {
                            return this.renderGroups(group, i);
                        })
                    }
                    <div ref={(el) => { this.messagesEnd = el; }} className="box__end" />
                </Fragment>
            )
        }

        return null;
    }

    render() {
        return (
            <div className={classnames('livechat', { 'livechat--open': this.state.showBot })}>
                <div className="box">
                    <ChatHeader onClick={this.toggleVisibility} showBot={this.state.showBot} />
                    <div className="box__content">
                        <div className="box__wrapper">
                            {this.renderMessages(this.state.messages)}
                        </div>
                    </div>
                    <ChatInput
                        placeholder="Írj egy üzenetet"
                        onKeyPress={this.handleTextInputKeyPress}
                        autoFocus={true}
                    />
                </div>
            </div>
        );
    }
}

export default Chatbot;
