import React from 'react';
import moment from 'moment';
import 'moment/locale/hu';

const ChatTimeSep = (props) => {
    let timeFormat = 'MMMM DD. HH:mm';
    let groupTime = moment.unix(props.timestamp);

    let groupTimeClone = groupTime.clone().startOf('day');
    let today = moment().startOf('day');
    let yesterday = today.clone().subtract(1, 'days');

    if (groupTimeClone.isSame(today, 'd')) {
        timeFormat = '[ma] HH:mm';
    } else if (groupTimeClone.isSame(yesterday, 'd')) {
        timeFormat = '[tegnap] HH:mm';
    } else if (today.diff(groupTimeClone, 'days') < 7) {
        timeFormat = 'dddd HH:mm';
    }

    return (
        <div className="box__sep">
            {groupTime.locale('hu').format(timeFormat)}
        </div>
    )
};

export default ChatTimeSep;
