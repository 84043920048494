import React from 'react';
import classnames from 'classnames';
import logo from '../../images/logo_white.png'

const ChatHeader = (props) => {
    return (
        <div className="box__header">
            <div className="box__title">
                <img src={logo} alt="OBOR Bank" />
            </div>
            <div
                className="box__toggle"
                onClick={props.onClick}
            >
                <i className={classnames('fa', {
                    'fa-plus': !props.showBot,
                    'fa-minus': props.showBot,
                })}/>
            </div>
        </div>
    );
};

export default ChatHeader;
