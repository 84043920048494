import React from 'react';
import Card from './Card';

class Cards extends React.Component {
    render() {
        return (
            <div className="media media--bot">
                <div className="media-cards">
                    <div className="media-cards-wrapper">
                         <div className="media-cards-container clearfix" style={{ width: this.props.cards.length * 272 }}>
                             {this.props.cards.map((card, i) => <Card key={i} payload={card} />)}
                         </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cards;
