import React from 'react';
import classnames from 'classnames';
import QuickReply from './QuickReply';
import Typing from './Typing';

class QuickReplies extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: null,
        };

        let urlParams = new URLSearchParams(window.location.search);
        this.userId = urlParams.get('userId') || 1;

        this.onClick = this.onClick.bind(this);
    }

    onClick(e, payload, text, selected) {
        this.setState({
            selected: selected
        }, () => {
            this.props.replyClick(e, payload, text);
        });
    }

    renderQuickReply(reply, i) {
        return <QuickReply
            key={i}
            index={i}
            selected={this.state.selected}
            onClick={this.onClick} reply={reply}
        />;
    }

    renderQuickReplies(quickReplies) {
        if (quickReplies) {
            return (
                <div className="media__options">
                    {quickReplies.map((reply, i) => this.renderQuickReply(reply, i))}
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div className={classnames("media media--bot", { 'media--alt': this.userId%2 === 0 })}>
                <div className="media__body">
                    {this.props.text && <div className="media__bubble">
                        <Typing animate={true}>
                            <span className="media__text">{this.props.text}</span>
                        </Typing>
                    </div>}
                    {this.renderQuickReplies(this.props.payload)}
                </div>
            </div>
        );
    }
}

export default QuickReplies;
