import React from 'react';
import classnames from 'classnames';

const WAIT_TIME = 10;

class Typing extends React.Component {
    state = {
        text: [],
        toType: ''
    };

    traverse(node) {
        if (React.isValidElement(node)) {
            return React.Children.map(node.props.children, child => this.traverse(child));
        } else if (Array.isArray(node)) {
            return node.map(el => this.traverse(el));
        }

        return String(node);
    };

    componentDidMount() {
        this.hasMounted = true;
        this.started = false;

        if (this.props.animate) {
            let child = this.traverse(this.props.children);
            let text = Array.isArray(child) ? child.shift() : child;
            this.updateState({
                text: text.split(''),
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.hasMounted &&
            !this.started
        ) {
            this.started = true;
            this.type();
        }
    }

    componentWillUnmount() {
        this.hasMounted = false;
    }

    updateState = async state => {
        if (this.hasMounted) {
            return new Promise(resolve => {
                this.setState(state, resolve);
            });
        }
    };

    async type() {
        try {
            if (this.state.text.length > 0) {
                let char = this.state.text.shift();
                await this.updateState({
                    toType: this.state.toType + char,
                });

                setTimeout(async () => {
                    await this.type();
                }, WAIT_TIME);
            }

        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div className={classnames('typing', {'typing--finished': this.state.text.length === 0})}>
                {this.state.text.length > 0 && <div className="typing__animate">{this.state.toType}</div>}
                {this.props.children}
            </div>
        )
    }
}

export default Typing;
